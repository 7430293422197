// @ts-nocheck
import Breadcrumbs from 'atoms/breadcrumbs/breadcrumbs';
import Icon from 'atoms/icons/icon';
import Sheet from 'atoms/sheet/sheet';
import * as dynamicGridSagaActions from 'models/dynamicGrid/sagaActions';
import * as dynamicGridSelectors from 'models/dynamicGrid/selector';
import * as tableColumnSagaActions from 'models/tableColumns/sagaActions';
import * as tableColumnSelectors from 'models/tableColumns/selector';
import SideCar from 'molecules/sidecar/sidecar';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import CustomizeViewModal from 'organisms/CustomizeViewModal';
import ThetaDeleteModal from 'organisms/deleteModal/deleteModal';
import * as deleteModalSagaActions from 'organisms/deleteModal/sagaActions';
import * as deleteModalSelectors from 'organisms/deleteModal/selectors';
import ExportGrid from 'organisms/exportGrid';
import ThetaMenu from 'organisms/thetaMenu/thetaMenu';
import UploadData from 'organisms/uploadData';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThetaGridPaginated from 'templates/thetaGridPaginated';
import { REQUESTS } from 'utils/requests';
import * as constants from './constants';
import { isMasterDataValidStoreSkuCombinationsDelete, isMasterDataValidStoreSkuCombinationsWrite } from 'models/user/selector';
import GridActionBar from 'atoms/GridActionBar';
import GridCsvUpload from 'organisms/uploadData/gridCsvUpload';
import { isNewUploadEnabled } from 'models/user/selector';

const rowIdentifier = 'ValidStoreSKUID';
const STORE_SKU_COMBINATION_DELETE_MODAL = 'storeSkuCombinationDeleteModal';

const StoreSKUCombinationMasterData: React.FunctionComponent = props => {
	const [openFilter, setOpenFilter] = React.useState<boolean>();
	const [isUploadEnabled, toggleUpload] = React.useState<boolean>(false);
	const hasWriteAccess = useSelector(isMasterDataValidStoreSkuCombinationsWrite);
	const hasDeleteAccess = useSelector(isMasterDataValidStoreSkuCombinationsDelete);
	const tableId = constants.TABLE_ID.toString();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const gridMeta = useSelector(state => dynamicGridSelectors.getGridMetadata(state, constants.TABLE_ID));

	const hasSearchFilters = useSelector(state => dynamicGridSelectors.hasSearchFilters(state, constants.TABLE_ID));

	const isCustomizeViewModalEnabled = useSelector(state => tableColumnSelectors.isCustomizeViewModalEnabled(state));

	// React.useEffect(() => {
	//   dispatch(tableColumnSagaActions.fetchTableColumns(constants.TABLE_ID));
	// }, [dispatch, tableId]);

	React.useEffect(() => {
		if (openFilter === undefined) {
			setOpenFilter(hasSearchFilters);
		}
	}, [hasSearchFilters]);

	const handleFilterClick = React.useCallback(() => {
		setOpenFilter(!openFilter);
	}, [openFilter]);

	const handleRefreshClick = React.useCallback(() => {
		dispatch(
			dynamicGridSagaActions.refreshGridData({
				tableId: constants.TABLE_ID,
			})
		);
	}, [dispatch]);

	const handleBackClick = React.useCallback(() => {
		navigate(`/masterData`);
	}, [navigate]);

	const handleResetTableColumns = React.useCallback(async () => {
		dispatch(tableColumnSagaActions.resetTableColumnsAg(constants.TABLE_ID));

	}, [dispatch, tableId]);

	const customizeViewHandler = React.useCallback((isCustomizeViewModalEnabled: boolean) => {
		dispatch(tableColumnSagaActions.setCustomizeViewModal(isCustomizeViewModalEnabled));
	}, []);

	const selectedStoreSkuCombinations = useSelector(state =>
		dynamicGridSelectors.getTableSelection(state, constants.TABLE_ID)
	);

	const openModalName = useSelector(state => deleteModalSelectors.getOpenModalName(state));

	const handleBulkDeleteClick = () => {
		dispatch(deleteModalSagaActions.deleteClick(constants.TABLE_ID));
	};

	const deleteStoreSkuCombinations = () => {
		dispatch(deleteModalSagaActions.toggleModalOpen(STORE_SKU_COMBINATION_DELETE_MODAL));
	};

	const handleMenuItemClick = React.useCallback(
		(option: string) => {
			switch (option) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
					return customizeViewHandler(true);
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return handleResetTableColumns();
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return toggleUpload(true);
				case constants.MENU_ITEMS_VALUES.DELETE:
					return deleteStoreSkuCombinations();
				default:
					return;
			}
		},
		[handleResetTableColumns, selectedStoreSkuCombinations]
	);

	const getDisabledStatus = React.useCallback(
		(option: any) => {
			switch (option.value) {
				case constants.MENU_ITEMS_VALUES.CUSTOMIZE_VIEW:
				case constants.MENU_ITEMS_VALUES.RESET_VIEW:
					return false
				case constants.MENU_ITEMS_VALUES.UPLOAD_DATA:
					return !hasWriteAccess;
				case constants.MENU_ITEMS_VALUES.DELETE:
					return !hasDeleteAccess || (
						!selectedStoreSkuCombinations?.selectedRowKeys?.length &&
						!!selectedStoreSkuCombinations?.isSelectAll
					);
				default:
					return true;
			}
		},
		[selectedStoreSkuCombinations, hasDeleteAccess, hasWriteAccess]
	);

	const hideCustomizeViewModal = React.useCallback(() => {
		customizeViewHandler(false);
	}, []);

	const uploadCloseClickHandler = React.useCallback(() => {
		toggleUpload(false);
	}, []);

	const gridConfig = {
		enableSelection: true,
		enableSorting: true,
		enableMultiSort: true,
		selectionCallBack: () => { },
		gridActions: hasDeleteAccess
	};

	const templateConfig = {
		gridConfig,
		isFilterEnabled: openFilter === undefined ? false : openFilter,
		tableId: constants.TABLE_ID,

		dataProviderOptions: {
			type: 'POST',
			version: 2,
			...REQUESTS.POST.GET_GRID_DETAILS_V2,
			pathParams: { tableId: 22 },
		},
		selectRowKey: rowIdentifier
	};

	const hideDeleteModal = () => {
		dispatch(deleteModalSagaActions.toggleModalClose());
	};

	const isNewUpload = useSelector(isNewUploadEnabled);


	return (
		<React.Fragment>
			<Stack isVertical>
				<StackItem isStretch>
					<GridActionBar>
						<Stack gutter="medium" align="space-between">
							<StackItem isGrow>
								<Breadcrumbs crumbs={props.breadcrumbs} />
							</StackItem>
							<StackItem>
								<Stack gutter="medium">
									<StackItem isGrow />
									{isNewUpload && gridMeta?.UploadTableID && <StackItem>
										<GridCsvUpload uploadTableId={gridMeta?.UploadTableID} parentTableId={tableId} />
									</StackItem>}
									<StackItem>
										<Icon
											isActive
											type="secondary"
											faAlias="faRecycle"
											onClick={handleRefreshClick}
										/>
									</StackItem>

									<StackItem>
										<ExportGrid tableId={constants.TABLE_ID} />
									</StackItem>
									<StackItem>
										<ThetaMenu
											type="3Dots"
											options={
												constants.MENU_ITEMS.filter(x => x.value !== 'CUSTOMIZE_VIEW')
											}
											handleItemClick={handleMenuItemClick}
											isDisabled={getDisabledStatus}
										/>
									</StackItem>
								</Stack>
							</StackItem>
						</Stack>
					</GridActionBar>

				</StackItem>
				<StackItem isStretch isGrow>
					<Sheet isPage>
						<Stack isVertical gutter="medium">


							<StackItem isGrow isStretch>
								<ThetaGridPaginated config={templateConfig} />
							</StackItem>
						</Stack>
					</Sheet>
				</StackItem>
			</Stack>


			{isCustomizeViewModalEnabled && (
				<CustomizeViewModal hideModal={hideCustomizeViewModal} tableId={constants.TABLE_ID} />
			)}
			{isUploadEnabled && (
				<SideCar isActive={isUploadEnabled} handleOnClose={uploadCloseClickHandler}>
					<UploadData tableId={tableId} />
				</SideCar>
			)}

			{openModalName === STORE_SKU_COMBINATION_DELETE_MODAL && (
				<ThetaDeleteModal
					hideModal={hideDeleteModal}
					modalTitle={'Store SKU Combination'}
					title={`(${selectedStoreSkuCombinations?.isSelectAll
						? 'All'
						: selectedStoreSkuCombinations?.selectedRowKeys?.length
						}) selected item(s)`}
					title={'selected items'}
					handleDeleteClick={handleBulkDeleteClick}
					isLocked={true}
				/>
			)}
		</React.Fragment>
	);
};
export default StoreSKUCombinationMasterData;
