// @ts-nocheck
import Button from "atoms/button/button";
import Divider from "atoms/divider/divider";
import Heading from "atoms/heading/heading";
import Stack from "molecules/stack/stack";
import StackItem from "molecules/stackItem/stackItem";
import React from "react";
import Modal from "atoms/modal/modal";
import Sheet from "atoms/sheet/sheet";
import SheetBody from "atoms/sheet/sheetBody/sheetBody";
import SheetAction from "atoms/sheet/sheetAction/sheetAction";
import Loader from "atoms/loader";
import { useDispatch, useSelector } from "react-redux";
import { exportGridData } from "models/dynamicGrid/sagaActions";
import TextInput from "atoms/TextInput/textInput";
import Label from "atoms/label/label";
import Icon from "atoms/icons/icon";
import { Spin, Tooltip } from "antd";
import { isLoadingEnabled } from "models/loaders/selectors";
import { useEffect } from "react";
import { Events } from "molecules/ReactGrid/agGrid/utils/events";
import { GRID_EVENTS } from "molecules/ReactGrid/agGrid/constants";
import { getTableColumns } from "models/tableColumns/selector";

const ExportGrid = (props: Props) => {
  const {
    tableId,
    fieldGroups,
    extraFilters,
  } = props;
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = React.useState(false);
  const [exportName, updateExportName] = React.useState("");
  // const [selectColumnList, setSelectColumnList] =
  //   React.useState("KitDescription");
  const isLoading = useSelector((state) =>
    isLoadingEnabled(state, "download/" + tableId)
  );

  const getTableColumn = useSelector((state) =>
    getTableColumns(state, tableId)
  );
  
  // Map over the table columns to get the enabled column names
  const enabledColumns = getTableColumn?.filter(
    (column) => 
      column.IsColumnEnabled === true &&
      typeof column.AppColumnName === "string" &&
      column.AppColumnName !== "0" &&
      column.AppColumnName !== "additionalInfo" &&
      column.AppColumnName !== "HealthCheck"  
  ).map((column) => column.AppColumnAliasName ? 
    `${column.AppColumnName} as [${column.AppColumnAliasName}]` : column.AppColumnName
  ); // Extract only the column names
  
  // Join the enabled column names into a comma-separated string
  const selectColumnList = enabledColumns?.join(",");
  
  const exportGrid = () => {
    dispatch(
      exportGridData({
        tableId,
        exportName,
        fieldGroups,
        extraFilters,
        selectColumnList, // Pass the selectColumnList here
      })
    );
    toggleModal(false);
  };
  

  useEffect(() => {
    Events.subscribe(GRID_EVENTS.EXPORT, (data) => {
      toggleModal(true);
    });
  }, []);

  return (
    <>
      <Tooltip title={isLoading ? "Download In Progress" : "Export Data"}>
        {isLoading ? (
          <Spin />
        ) : (
          <Icon
            isActive
            type="secondary"
            onClick={() => toggleModal(true)}
            faAlias="faDownload"
          />
        )}
      </Tooltip>
      {isModalOpen && (
        <Modal hideModal={() => toggleModal(false)} height={300}>
          <Sheet isInset>
            <SheetBody>
              <Stack isVertical gutter="small">
                <StackItem isStretch>
                  <Heading isSecondary>Create a Named Export </Heading>
                </StackItem>
                <StackItem isStretch>
                  <Divider />
                </StackItem>

                <StackItem isGrow>
                  <Stack isVertical gutter="medium">
                    <StackItem isStretch>
                      <Label>Please enter a name for this file download</Label>
                    </StackItem>
                    <StackItem>
                      <TextInput
                        onChange={(e) => updateExportName(e.target.value)}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </SheetBody>
            <SheetAction>
              <Stack align="right" gutter="small">
                <StackItem>
                  <Button isSecondary handleClick={() => toggleModal(false)}>
                    {" "}
                    Cancel{" "}
                  </Button>
                </StackItem>
                <StackItem isStretch align="right">
                  <Button disabled={!exportName} handleClick={exportGrid}>
                    {" "}
                    Export Data{" "}
                  </Button>
                </StackItem>
              </Stack>
            </SheetAction>
          </Sheet>
        </Modal>
      )}
    </>
  );
};

export default ExportGrid;
