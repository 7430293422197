// @ts-nocheck
import '../advanced.scss';

import DateWrapper from 'atoms/datePicker/datePicker';
import DropDown from 'atoms/dropdown/dropdown';
import Icon from 'atoms/icons/icon';
import Tag from 'atoms/tag/tag';
import TextInput from 'atoms/TextInput/textInput';
import _ from 'lodash';
import { getGridFilters, getGridFiltersByColumn, getGridMetadata } from 'models/dynamicGrid/selector';
import { GRID_EVENTS } from 'molecules/ReactGrid/agGrid/constants';
import { Events } from 'molecules/ReactGrid/agGrid/utils/events';
import Stack from 'molecules/stack/stack';
import StackItem from 'molecules/stackItem/stackItem';
import MultiSelectDropdown from 'organisms/multiSelect/multiSelect';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { getDispatch, getState } from 'reducers';
import { formatDate } from 'utils/utilities';
import { v4 as uuidv4 } from 'uuid';

import * as query from '../query';
import * as sagaActions from '../sagaActions';
import * as types from '../type';
import agGridSelectColumnDefinitions from './agGridSelectColumnDefinitions';
import { useSelector } from 'react-redux';
import { REQUESTS } from 'utils/requests';

type TProps = {
	parameters: types.TSearchParameter[];
	searchFilters: types.TSearchQuery[];
	tableId?: number;
	isServerHandled: boolean;
	allowOperator?: boolean;
	operator?: string;
	updateOperator?: (checked: boolean, event: MouseEvent) => void;
};

const defaultProps: Partial<TProps> = {
	parameters: [],
	searchFilters: [],
	isServerHandled: false,
	allowOperator: false,
	operator: 'AND',
	updateOperator: (checked: boolean, event: MouseEvent) => { }
};

const AgGridSearch: React.FunctionComponent<Partial<TProps>> = (props): React.ReactElement => { };

export const AgGridSearchMultiSelectFilter = ({
	currentQuery,
	currentColumnRef,
	tableId,
	onToggle,
	isEnabled,
	isInline,
	onUpdateQueries = () => null,
	isTagFilter = false
}) => {
	const gridMetaData = useSelector(state => getGridMetadata(state, tableId));
	const columnData = currentColumnRef?.colDef?.metaData;
	const selectedCriteria = query.getSearchParameters(currentColumnRef?.colDef?.metaData ?? {});
	const [isTagFilterOpen, setTagFilterOpen] = useState(false);
	const [filterOption, updateFilterOption] = useState(
		currentQuery?.operator || query.getCriteriaOperators(selectedCriteria ?? {})[0]
	);
	const [currentSearchInput, updateCurrentSearchInput] = useState(currentQuery?.query?.from || currentQuery?.query);
	const [currentSearchInputEnd, updateCurrentSearchInputEnd] = useState(currentQuery?.query?.to);
	const isOperatorBtwn = filterOption.value === 'btw' || filterOption.value === 'nbtw';
	const isOperatorEmpty = filterOption.value === 'empty' || filterOption.value === 'not.empty';
	const isOperatorIn = filterOption.value === 'in' || filterOption.value === 'not.in';
	const isOperatorBulkIn = filterOption.value === 'bulk.in';
	const isOperatorDate = selectedCriteria.type === 'date';
	const isEqualsOperator = filterOption.value === 'eq' || filterOption.value === 'neq';
	const multiSelectOptionsRequest = gridMetaData?.dataProviderOptions?.service === 'grid'
		|| gridMetaData?.dataProviderOptions?.service === "adminSettings" ||
		gridMetaData?.dataProviderOptions?.service === "upload"
		?
		REQUESTS.POST.GET_MULTI_SELECT_OPTIONS : REQUESTS.POST.GET_MULTI_SELECT_OPTIONS_ACL;

	console.log({ multiSelectOptionsRequest })
	const selectionProviderOptions = useMemo(
		() => ({
			type: 'POST',
			version: 2,
			...multiSelectOptionsRequest,
			pathParams: { tableId },
			tableId,
			service: gridMetaData?.dataProviderOptions?.service === "upload" ? "grid" :
				gridMetaData?.dataProviderOptions?.service,
			body: {}
		}),
		[tableId]
	);

	useEffect(() => {
		updateCurrentSearchInput(currentQuery?.query?.from || currentQuery?.query);
		updateCurrentSearchInputEnd(currentQuery?.query?.to);
		updateFilterOption(currentQuery?.operator || query.getCriteriaOperators(selectedCriteria ?? {})[0]);
	}, [currentQuery.query, currentQuery?.operator]);



	const dropdownOptions = query.getCriteriaOperators(selectedCriteria);
	const isInputVisible = !_.some(['empty', 'not.empty', 'is.true', 'is.false'], c => c === filterOption.value);

	const dispatch = getDispatch();

	const onUpdateInput = (e, type) => {
		if (type === 'date') {
			updateCurrentSearchInput(formatDate(e));
			return;
		}
		updateCurrentSearchInput(e.target.value);
	};


	const removeQuery = () => {
		let queries = getGridFiltersByColumn(getState(), tableId, currentQuery?.criteria?.rowIdentifier);
		let updated = _.filter(queries, q => q.id !== currentQuery.id);
		Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
			tableId,
		});
		dispatch(
			sagaActions.removeQuery({
				tableId,
				query: currentQuery,
			})
		);
		if (onUpdateQueries) {
			onUpdateQueries(updated);
		}
	};
	const updateQuery = params => {
		let updated = [];
		const searchQueriesAll = getGridFilters(getState(), tableId);
		Events.publish(GRID_EVENTS.CLEAR_SELECTION, {
			tableId,
		});
		const { id, selectedCriteria, searchInput, searchInputEnd, selectedOperator, isUpdate } = params;

		if (searchQueriesAll?.search.find(q => q?.criteria?.rowIdentifier === selectedCriteria?.rowIdentifier)) {
			updated = _.map(searchQueriesAll?.search, q => {
				if (q.criteria?.rowIdentifier === selectedCriteria?.rowIdentifier) {
					return {
						id: q.id || uuidv4(),
						criteria: selectedCriteria,
						query:
							selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
								? { from: searchInput, to: searchInputEnd }
								: searchInput,
						operator: selectedOperator
					};
				}
				return q;
			});
		}
		else {
			updated = [...searchQueriesAll?.search, {
				id: id || uuidv4(),
				criteria: selectedCriteria,
				query:
					selectedOperator && (selectedOperator.value === 'btw' || selectedOperator.value === 'nbtw')
						? { from: searchInput, to: searchInputEnd }
						: searchInput,
				operator: selectedOperator
			}]
		}


		if (onUpdateQueries) {
			onUpdateQueries(_.filter(updated, val => val.id));
		}
		setTagFilterOpen(false);
		dispatch(
			sagaActions.updateAgQueries({
				searchQueries: updated,
				tableId
			})
		);
		// currentColumnRef.setFilterActive();
	};
	const handleSubmit = (e, isClick, options) => {

		const searchInput = (options?.currentSearchInput || currentSearchInput)?.trim();
		const searchInputEnd = (options?.currentSearchInputEnd || currentSearchInputEnd)?.trim();
		if ((!searchInput || (isOperatorBtwn && !searchInputEnd?.trim())) && isInputVisible) {
			return;
		}
		if (e?.keyCode === 13 || isClick) {

			updateQuery({
				selectedCriteria,
				searchInput,
				searchInputEnd,
				selectedOperator: options?.filterOption || filterOption,
				tableId,
				id: currentQuery?.id,
				isUpdate: currentQuery?.id
			});
		}
	};

	const onUpdateModel = ({ dateEvent, filter, isRange }) => {
		if (filter) {
			updateFilterOption(filter);
			if (
				(filter.value === 'btw' || filter.value === 'nbtw') &&
				(!currentSearchInput || !currentSearchInputEnd)
			) {
				return;
			}
			if (isEqualsOperator || isOperatorIn) {
				return;
			}
			// handleSubmit(null, true, {
			// 	filterOption: filter
			// });
			return;
		}
		if (dateEvent) {
			const dateValue = dateEvent.target.value;
			if (_.isArray(dateValue)) {
				updateCurrentSearchInput(formatDate(dateValue[0]));
				updateCurrentSearchInputEnd(formatDate(dateValue[1]));
			} else {
				updateCurrentSearchInput(formatDate(dateValue));
			}
			// handleSubmit(null, true, {
			// 	currentSearchInput: _.isArray(dateValue) ? formatDate(dateValue[0]) : formatDate(dateValue),
			// 	currentSearchInputEnd: _.isArray(dateValue) ? formatDate(dateValue[1]) : ''
			// });
		}
	};

	const componentData = useMemo(() => {
		// if (gridMetaData?.MultiSelectOptions) {
		return agGridSelectColumnDefinitions.MULTISELECT_CONFIG.getTemplate({
			isOperatorBtwn,
			currentSearchInput,
			isOperatorIn,
			currentSearchInputEnd,
			isOperatorBulkIn,
			selectionProviderOptions,
			isOperatorEmpty,
			updateCurrentSearchInput,
			updateCurrentSearchInputEnd,
			isOperatorDate,
			isEqualsOperator,
			selectedCriteria,
			onUpdateModel,
			onUpdateInput,
			handleSubmit,
			isInline,
			columnData
		})
		// } else {
		// 	return agGridSelectColumnDefinitions.DEFAULT_CONFIG.getTemplate({
		// 		isOperatorBtwn,
		// 		currentSearchInput,
		// 		isOperatorIn,
		// 		isOperatorBulkIn,
		// 		selectedCriteria,
		// 		currentSearchInputEnd,
		// 		updateCurrentSearchInput,
		// 		updateCurrentSearchInputEnd,
		// 		selectionProviderOptions,
		// 		isOperatorEmpty,
		// 		onUpdateModel,
		// 		isOperatorDate,
		// 		isEqualsOperator,
		// 		onUpdateInput,
		// 		handleSubmit,
		// 		isInline,
		// 		columnData
		// 	})
		// }

	}, [isOperatorBtwn, currentSearchInput, isOperatorIn, currentSearchInputEnd, isOperatorBtwn, isOperatorDate, isEqualsOperator, gridMetaData, filterOption, isOperatorEmpty, isOperatorBulkIn])


	if (!currentColumnRef) {
		return;
	}
	const isTagEnabled = isTagFilter && !isTagFilterOpen;

	if (isTagEnabled) {
		return (
			currentQuery && (
				<Tag onClick={() => setTagFilterOpen(true)} closable onClose={removeQuery}>
					{_.truncate(
						`${currentQuery?.criteria?.displayName} ${currentQuery?.operator?.displayName} ${_.isObject(currentQuery?.query)
							? `${currentQuery?.query?.from} And ${currentQuery?.query?.to}`
							: currentQuery?.query ?? ''
						}`,
						20
					)}
				</Tag>
			)
		);
	}

	return (
		<Stack gutter="small" >
			<StackItem isStretch isGrow >
				<Stack isVertical={!isTagFilter} gutter="small">
					{isTagFilter && <StackItem position="center">{currentQuery?.criteria?.displayName}</StackItem>}
					<StackItem isStretch isGrow>
						<DropDown
							key={'options'}
							selectedOption={filterOption}
							options={dropdownOptions}
							updateHandler={e => {
								onUpdateModel({ filter: e });
							}}
						/>
					</StackItem>
					{componentData}
				</Stack>
			</StackItem>
			<StackItem>
				<Stack isVertical={!isTagFilter} gutter={isTagFilter ? "medium" : 'small'} align={!isTagFilter && 'space-between'} >
					<StackItem align='center' position='center' isGrow >
						<Icon
							isActive
							size={'small'}
							faAlias={'faSave'}
							onClick={(e) => handleSubmit(e, true)}
						></Icon>
					</StackItem>
					{currentQuery?.id && <StackItem align='end' position='center' isGrow >
						<Icon
							isActive
							size={'small'}
							faAlias={'faTrash'}
							onClick={() => removeQuery(currentQuery)}
						></Icon>
					</StackItem>
					}
				</Stack>
			</StackItem>
		</Stack>
	);
};
